import { Options } from '../index';
import Api from '../library/Api';
import Cookie, { CookieKey } from '../library/Cookie';
import EventEmitter from '../library/EventEmitter';
import Tracker from '../library/Tracker';
import { trackerEventNames } from '../library/Constants';
import { utils } from '../utils/Utils';
import { ICampaignData } from 'interface/ICampaignData';

class CampaignService extends EventEmitter {
  public static readonly EXPIRATION_TIME = 1 / 24; // -> 1/24th of a day = 1hour
  public static readonly ENDPOINT_CAMPAIGN_SERVICE = utils.getAbsoluteResourcePath('campaignService');
  public static readonly CACHE_KEY = 'campaign-data';
  public static readonly PARAMS_CACHE_KEY = 'campaign-request-params';

  private static readonly DEFAULT_CAMPAIGN_DATA: ICampaignData = {
    act: 'ACT0000000100ACT',
    partner: '',
    channel: '',
    usage: '',
    offer: '',
    brand: ''
  };

  private api: Api;
  private tracker: Tracker;

  public options: Options;

  constructor(api: Api, tracker: Tracker, options: Options) {
    super();
    this.api = api;
    this.tracker = tracker;
    this.options = options;
  }

  private getParams() {
    const { act, locale } = this.options;

    return {
      act,
      locale,
      hasReferrer: !!utils.getReferrer(),
      referrer: utils.getReferrerDomain(),
      landingUrl: this.extractLandingUrl()
    };
  }

  private extractLandingUrl() {
    return utils
      .getLandingUrl()
      .split('?')
      .shift()
      .replace(/http[s]?:\/\//i, '');
  }

  public async getData(): Promise<ICampaignData> {
    const params = this.getParams();
    const url = CampaignService.ENDPOINT_CAMPAIGN_SERVICE + utils.generateGetParameterString(params);

    const payload = {
      cacheKey: CampaignService.CACHE_KEY,
      paramsCacheKey: CampaignService.PARAMS_CACHE_KEY,
      params: this.getParams()
    };

    try {
      const data = await this.api.get(url, payload);

      if (data.hasOwnProperty('act')) {
        Cookie.set(CookieKey.ACT, JSON.stringify(data), CampaignService.EXPIRATION_TIME);
      }

      Cookie.set(CookieKey.ACT, params.act);

      return data;
    } catch (error) {
      this.tracker.track(trackerEventNames.CAMPAIGN_SERVICE_REQUEST_FAILED, {
        error,
        url,
        payload
      });

      this.tracker.track(trackerEventNames.CAMPAIGN_SERVICE_USING_DEFAULT_ACT, {
        message: 'Campaign service failed - using default ACT value',
        defaultAct: CampaignService.DEFAULT_CAMPAIGN_DATA.act,
        originalError: error,
        url,
        payload
      });

      return { ...CampaignService.DEFAULT_CAMPAIGN_DATA };
    }
  }
}

export default CampaignService;
