import Tracker from './Tracker';
import { trackerEventNames } from './Constants';
import { utils } from '../utils/Utils';
import { IFetchOptions } from '../interface/IFetchOptions';

class Api {
  private tracker: Tracker;
  private cache: any;
  public reqCache: any = {};

  constructor(tracker: Tracker, cache: any) {
    this.tracker = tracker;
    this.cache = cache;
  }

  public async request(endpoint, payload: any, options) {
    const { retryAttempts = 1, retryDelay = 1000, timeout } = options;

    let requestPromise;
    if (timeout) {
      requestPromise = utils.fetchWithTimeout(endpoint, payload, timeout);
    } else {
      requestPromise = fetch(endpoint, payload);
    }

    try {
      const response = await requestPromise;
      const responseJson = await response.json();

      if (response.status < 400) {
        return responseJson;
      } else {
        throw new Error(responseJson.statusText || responseJson.message);
      }
    } catch (error) {
      this.handleError(
        endpoint,
        retryAttempts,
        {
          statusText: error?.message
        },
        payload
      );

      if (retryAttempts <= 1) {
        throw error;
      }

      await utils.sleep(retryDelay);
      const data = await this.request(endpoint, payload, {
        retryAttempts: retryAttempts - 1,
        retryDelay,
        timeout
      });

      return data;
    }
  }

  public async requestWithCache(endpoint, payload, options: IFetchOptions) {
    const { cacheKey, params = {}, paramsCacheKey, retryAttempts = 1, retryDelay = 0 } = options;
    const cachedData = this.cache.get(cacheKey);
    const cachedParams = this.cache.get(paramsCacheKey);

    if (cachedData && JSON.stringify(cachedParams) === JSON.stringify(params)) {
      return Promise.resolve(cachedData);
    }

    this.cache.set(paramsCacheKey, JSON.stringify(params));

    const promiseCacheHash = JSON.stringify({ cacheKey, endpoint, payload });
    const promiseCache = this.reqCache[promiseCacheHash];

    try {
      if (!promiseCache) {
        this.reqCache[promiseCacheHash] = this.request(endpoint, payload, { retryAttempts, retryDelay });
      }
      const data = await this.reqCache[promiseCacheHash];

      this.reqCache[promiseCacheHash] = null;
      this.cache.set(cacheKey, JSON.stringify(data) || '');
      return data;
    } catch (error) {
      this.handleError(endpoint, retryAttempts, error, payload);
      return Promise.reject(error);
    }
  }

  public handleError(endpoint, retryAttempts, error, payload = {}) {
    const errorData = {
      endpoint,
      retryAttempts,
      text: error.statusText || error.message,
      code: error.status,
      method: 'Api:handleError()',
      payload
    };

    this.tracker.track(trackerEventNames.REQUEST_FAILED, errorData);
  }

  public post(endpoint: string, data = {}, headers = {}, options: IFetchOptions = {}) {
    const payload = {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    };

    return this.request(endpoint, payload, options);
  }

  public get(endpoint: string, options: IFetchOptions = {}, payload = {}) {
    const { cacheKey } = options;
    return cacheKey
      ? this.requestWithCache(endpoint, { ...payload, method: 'GET' }, options)
      : this.request(endpoint, { ...payload, method: 'GET' }, options);
  }
}

export default Api;
