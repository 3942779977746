import { emitWindowEvent } from '../utils/emitWindowEvent';
import { Options } from '../index';
import { ILeadData, LeadSourceOptions } from '../interface/ILeadData';
import { IMarketingData } from '../interface/IMarketingData';
import Api from '../library/Api';
import {
  domSelector,
  emitterEvents,
  leadKeys,
  leadResponseKey,
  localStorageKey,
  sessionStorageKey,
  trackerEventNames,
  urlParameter,
  windowEvents
} from '../library/Constants';
import Cookie, { CookieKey, CookieLifetime } from '../library/Cookie';
import EventEmitter from '../library/EventEmitter';
import Tracker from '../library/Tracker';
import Buffer from '../models/Buffer';
import Lead from '../models/Lead';
import { utils } from '../utils/Utils';
import Session from '../valueobject/Session';
import CampaignService from './CampaignService';
import GtmService from './GtmService';
import MarketingService from './MarketingService';
import OptimizelyService from './OptimizelyService';

declare let document: Document;

interface ReactivationOptions {
  disableMergeDelay?: boolean;
  gid?: string;
}

const REACTIVATION_OPTIONS_DEFAULTS: ReactivationOptions = {
  disableMergeDelay: false,
  gid: null
};

class LeadhandlerService extends EventEmitter {
  public static readonly MAX_RETRY_REQUEST_ATTEMPTS = 3;
  public static REQUEST_ATTEMPT_DELAY = 9000;
  public static readonly ENDPOINT_LEADHANDLER = utils.getAbsoluteResourcePath('leadHandler');
  public static readonly ENDPOINT_REACTIVATION = utils.getAbsoluteResourcePath('reactivation');
  public lead: Lead;
  public readonly session: Session;
  public api: any;
  public tracker: any;

  // TODO: backward compatibility property.
  // To replace with: sessionLayer.leadHandler.getQuestionsById(questionnaireKey),
  // where questionnaireKey: questionnaireUUID-OfferType

  buffers: { [key: string]: any[] };
  private readonly options: Options;
  private buffer: Buffer;
  private campaignService: CampaignService;
  private gtmService: GtmService;
  private localStorage: any;
  private marketingService: MarketingService;
  private optimizelyService: OptimizelyService;
  private sessionStorage: any;
  public requestAttempt: number;

  constructor(
    session: Session,
    marketingService: MarketingService,
    campaignService: CampaignService,
    optimizelyService: OptimizelyService,
    localStorage: Storage,
    sessionStorage: Storage,
    options: Options,
    api: Api,
    tracker: Tracker,
    gtmService: GtmService
  ) {
    super();
    this.options = options;
    this.session = session;
    this.lead = this.session.lead;
    this.buffer = new Buffer();
    this.buffers = {};
    this.localStorage = localStorage;
    this.api = api;
    this.tracker = tracker;
    this.marketingService = marketingService;
    this.campaignService = campaignService;
    this.optimizelyService = optimizelyService;
    this.gtmService = gtmService;
    this.sessionStorage = sessionStorage;

    if (this.session.isReactivation()) {
      this.setLeadSource(LeadSourceOptions.OneClick);
    }
  }
  // TODO extract API methods to dedicated api class

  // -- API ---------------------------------------------------------------------------------------- //

  /**
   * submitting the buffered data to lead, when required fields are not empty
   *
   * Encapsulates saveToBufferAndSubmit() to make it all more self-explanatory
   * and still keeps the external api method call push()
   *
   * @param {String} bufferCacheKey — "questionnaire uuid-offerType"
   * @param {Object} leadData - { salesforceKey: value }
   */
  public async push(
    bufferCacheKey: string,
    leadData: Record<string, any>,
    { silent } = { silent: false }
  ): Promise<any> {
    const result = this.saveToBufferAndSubmit(leadData, this.validateBufferCacheKey(bufferCacheKey));

    if (!silent) {
      this.emit(emitterEvents.DATA_PUSHED, leadData);
    }

    return result;
  }

  public async pushWithoutDelay(bufferCacheKey: string): Promise<any> {
    return this.saveToBufferAndSubmit({ delay: 0 }, this.validateBufferCacheKey(bufferCacheKey));
  }

  private isGidFromUrl(): boolean {
    if (this.lead.get(leadKeys.GID) === this.session.getPreviousGidFromUrl()) {
      return true;
    }

    if (this.lead.get(leadKeys.GID) === this.session.getSessionGidFromUrl()) {
      return true;
    }

    return false;
  }

  public async upsert(leadData: Record<string, any>): Promise<any> {
    if (!Cookie.has(CookieKey.GID) && !this.isGidFromUrl()) {
      return Promise.resolve({});
    }

    return this.submitLeadUpdates(leadData);
  }

  public setLeadSource(leadSource: LeadSourceOptions): void {
    this.lead.add(leadKeys.LEAD_SOURCE, leadSource);
  }

  public async reactivateLead(options: ReactivationOptions = REACTIVATION_OPTIONS_DEFAULTS): Promise<any> {
    if (this.session.isReactivatedRecently()) {
      return Promise.resolve();
    }

    const isAReactivationURL = this.session.isReactivation();

    let newGid = null;

    if (isAReactivationURL && options.gid) {
      this.tracker.track(trackerEventNames.REACTIVATION_OVERLAP, {
        gid: {
          optionsGid: options.gid,
          urlGid: this.lead.get(leadKeys.GID)
        },
        locale: this.lead.get(leadKeys.LOCALE),
        method: 'reactivateLead'
      });
    }

    if (options.gid) {
      newGid = await this.reactivateLeadFromOptions(options);
    } else if (isAReactivationURL) {
      newGid = await this.reactivateLeadFromUrlParams(false);
    } else {
      newGid = await this.reactivateLeadFromLocalStorage(options);
    }

    if (newGid) {
      emitWindowEvent(windowEvents.lead.REACTIVATED, null);

      return newGid;
    }
  }

  public async reactivateLeadPlusPayload(): Promise<any> {
    if (this.session.isReactivatedRecently()) {
      return Promise.resolve();
    }
    const newGid = await this.reactivateLeadFromUrlParams(true);
    if (newGid) {
      emitWindowEvent(windowEvents.lead.REACTIVATED, null);
      return newGid;
    }
  }

  private async reactivateLeadFromOptions(options: ReactivationOptions): Promise<any> {
    if (!this.session.isValidGid(options.gid)) {
      return Promise.resolve();
    }

    if (this.isRecentLead(options.gid)) {
      this.tracker.track(trackerEventNames.REACTIVATION_INVALID, {
        error: 'recentLead',
        gid: options.gid,
        locale: this.lead.get(leadKeys.LOCALE),
        method: 'reactivateLeadFromOptions'
      });

      return Promise.resolve();
    }

    const reactivationGid = this.session.createReactivationGid();

    this.setLeadSource(LeadSourceOptions.Web2Lead);

    const marketingLeadData = await this.marketingService.getLeadData();

    this.tracker.track(trackerEventNames.REACTIVATION_STARTED, {
      gid: options.gid,
      source: 'options',
      locale: this.lead.get(leadKeys.LOCALE),
      method: 'reactivateLeadFromOptions'
    });

    return this.api
      .post(
        LeadhandlerService.ENDPOINT_REACTIVATION,
        {
          [leadKeys.CONTROLLING_CHANNEL]: marketingLeadData[leadKeys.CONTROLLING_CHANNEL],
          [leadKeys.DISABLE_MERGE_DELAY]: options.disableMergeDelay,
          [leadKeys.GID]: reactivationGid,
          [leadKeys.LANDING_URL]: this.lead.get(leadKeys.LANDING_URL),
          [leadKeys.LEAD_CUSTOMER_GID]: options.gid,
          [leadKeys.LEAD_SOURCE]: this.lead.get(leadKeys.LEAD_SOURCE),
          [leadKeys.LEAD_URL]: this.session.getUrl(),
          [leadKeys.MARKETING_OFFER_V2]: marketingLeadData[leadKeys.MARKETING_OFFER_V2],
          [leadKeys.MARKETING_PARTNER]: marketingLeadData[leadKeys.MARKETING_PARTNER],
          [leadKeys.UPSERT]: 'no',
          [leadKeys.USAGE]: marketingLeadData[leadKeys.USAGE],
          [leadKeys.USER_DEVICE]: marketingLeadData[leadKeys.USER_DEVICE]
        },
        {
          'Content-Type': 'application/json; charset=utf-8'
        }
      )
      .then((response = []) => {
        const [data = {}] = response;
        const { gid } = data;

        if (!gid) {
          return Promise.resolve();
        }

        Cookie.set(CookieKey.GID, gid, CookieLifetime.ONE_HOUR);

        this.updateGidAroundAllSessionLayer(gid);
        this.localStorage.setItem(localStorageKey.REACTIVATION_TIME, Date.now().toString());

        const formClientDomElement = this.querySelectFormClientDomElement();

        if (formClientDomElement) {
          const pushKey = this.buildPushKey(formClientDomElement);

          this.push(pushKey, {
            GID__c: gid
          });
        }

        this.tracker.track(trackerEventNames.REACTIVATION_COMPLETED, {
          gid: options.gid,
          source: 'options',
          locale: this.lead.get(leadKeys.LOCALE),
          method: 'reactivateLeadFromOptions'
        });

        return gid;
      })
      .catch((err: Error) => {
        this.tracker.track(trackerEventNames.REACTIVATION_FAILED, {
          error: err,
          gid: this.lead.get(leadKeys.GID),
          locale: this.lead.get(leadKeys.LOCALE),
          method: 'reactivateLeadFromOptions'
        });
      });
  }

  public async reactivateLeadFromLocalStorage(options: ReactivationOptions): Promise<any> {
    const persistedGid = this.session.getGidFromLocalStorage();

    if (!persistedGid) {
      return Promise.resolve();
    }

    if (this.isRecentLead(persistedGid)) {
      this.tracker.track(trackerEventNames.REACTIVATION_INVALID, {
        error: 'recentLead',
        gid: persistedGid,
        locale: this.lead.get(leadKeys.LOCALE),
        method: 'reactivateLeadFromLocalStorage'
      });

      return Promise.resolve();
    }

    const reactivationGid = this.session.createReactivationGid();

    this.setLeadSource(LeadSourceOptions.Web2Lead);

    const marketingLeadData = await this.marketingService.getLeadData();

    this.tracker.track(trackerEventNames.REACTIVATION_STARTED, {
      gid: options.gid,
      source: 'local_storage',
      locale: this.lead.get(leadKeys.LOCALE),
      method: 'reactivateLeadFromLocalStorage'
    });

    return this.api
      .post(
        LeadhandlerService.ENDPOINT_REACTIVATION,
        {
          [leadKeys.CONTROLLING_CHANNEL]: marketingLeadData[leadKeys.CONTROLLING_CHANNEL],
          [leadKeys.DISABLE_MERGE_DELAY]: options.disableMergeDelay,
          [leadKeys.GID]: reactivationGid,
          [leadKeys.LANDING_URL]: this.lead.get(leadKeys.LANDING_URL),
          [leadKeys.LEAD_CUSTOMER_GID]: persistedGid,
          [leadKeys.LEAD_SOURCE]: this.lead.get(leadKeys.LEAD_SOURCE),
          [leadKeys.LEAD_URL]: this.session.getUrl(),
          [leadKeys.MARKETING_OFFER_V2]: marketingLeadData[leadKeys.MARKETING_OFFER_V2],
          [leadKeys.MARKETING_PARTNER]: marketingLeadData[leadKeys.MARKETING_PARTNER],
          [leadKeys.UPSERT]: 'no',
          [leadKeys.USAGE]: marketingLeadData[leadKeys.USAGE],
          [leadKeys.USER_DEVICE]: marketingLeadData[leadKeys.USER_DEVICE]
        },
        {
          'Content-Type': 'application/json; charset=utf-8'
        }
      )
      .then((response = []) => {
        const [data = {}] = response;
        const { gid } = data;

        if (!gid) {
          return Promise.resolve();
        }

        Cookie.set(CookieKey.GID, gid, CookieLifetime.ONE_HOUR);

        this.localStorage.setItem(localStorageKey.REACTIVATION_TIME, Date.now().toString());

        this.updateGidAroundAllSessionLayer(gid);

        const formClientDomElement = this.querySelectFormClientDomElement();

        if (formClientDomElement) {
          const pushKey = this.buildPushKey(formClientDomElement);

          this.push(pushKey, {
            GID__c: gid
          });
        }

        this.tracker.track(trackerEventNames.REACTIVATION_COMPLETED, {
          gid: options.gid,
          source: 'local_storage',
          locale: this.lead.get(leadKeys.LOCALE),
          method: 'reactivateLeadFromLocalStorage'
        });

        return gid;
      })
      .catch((err: Error) => {
        this.tracker.track(trackerEventNames.REACTIVATION_FAILED, {
          error: err,
          gid: this.lead.get(leadKeys.GID),
          locale: this.lead.get(leadKeys.LOCALE),
          method: 'reactivateLeadFromLocalStorage'
        });
      });
  }

  private isRecentLead(gid: string): boolean {
    return gid === Cookie.get(CookieKey.GID);
  }

  private async reactivateLeadFromUrlParams(useFormFields: boolean): Promise<any> {
    if (!window.location.search) {
      return Promise.resolve();
    }

    const requiredFields = ['upsert', 'LeadCustomerGID'];
    const urlParams = new URLSearchParams(window.location.search);
    const payload = {};

    this.setLeadSource(LeadSourceOptions.OneClick);

    urlParams.forEach(function (value, key) {
      if (key === urlParameter.DISABLE_REACTIVATION_DELAY) {
        Object.assign(payload, { disableMergeDelay: true });
        return Promise.resolve();
      }

      const name = key === 't' ? 'OM_Test_ID__c' : key; // mapping
      payload[name] = value.split(' ').join(','); // "1 2" -> "1,2"
    });

    Object.assign(payload, { [leadKeys.LEAD_URL]: this.session.getUrl() });

    if (useFormFields) {
      Object.assign(payload, this.lead.getData());
    } else {
      // Special case for Selected_Campaign__m
      if (this.lead.getData()[leadKeys.SELECTED_CAMPAIGN]) {
        Object.assign(payload, { [leadKeys.SELECTED_CAMPAIGN]: this.lead.getData()[leadKeys.SELECTED_CAMPAIGN] });
      }
    }

    const isFieldInPayload = (field: string): boolean => payload.hasOwnProperty(field);
    const isPayloadValid = requiredFields.every(isFieldInPayload);

    if (!isPayloadValid) {
      this.tracker.track(trackerEventNames.REACTIVATION_FAILED, {
        gid: this.lead.get(leadKeys.GID),
        locale: this.lead.get(leadKeys.LOCALE),
        message: 'Payload does not contain required fields',
        method: 'reactivateLeadFromUrlParams'
      });
      return Promise.resolve();
    }

    this.tracker.track(trackerEventNames.REACTIVATION_STARTED, {
      gid: this.session.getGid(),
      source: 'url_params',
      locale: this.lead.get(leadKeys.LOCALE),
      method: 'reactivateLeadFromUrlParams'
    });

    return this.marketingService.getLeadData().then((data) => {
      const reactivationGid = this.session.getGid();

      return this.api
        .post(
          `${LeadhandlerService.ENDPOINT_REACTIVATION}`,
          {
            ...payload,
            GID__c: reactivationGid,
            LeadSource: this.lead.get(leadKeys.LEAD_SOURCE),
            User_Device__c: data.User_Device__c,
            Marketing_Offer_V2__c: data.Marketing_Offer_V2__c,
            Usage__c: data.Usage__c,
            Controlling_Channel__c: data.Controlling_Channel__c,
            Marketing_Partner__c: data.Marketing_Partner__c
          },
          {
            'Content-Type': 'application/json; charset=utf-8'
          }
        )
        .then((response = []) => {
          const [data = {}] = response;
          const { gid } = data;

          Cookie.set(CookieKey.GID, gid, CookieLifetime.ONE_HOUR);

          this.localStorage.setItem(localStorageKey.REACTIVATION_TIME, Date.now().toString());

          const formClientDomElement = this.querySelectFormClientDomElement();

          if (gid && formClientDomElement) {
            const pushKey = this.buildPushKey(formClientDomElement);

            this.push(pushKey, {
              GID__c: gid
            });
          }

          this.tracker.track(trackerEventNames.REACTIVATION_COMPLETED, {
            gid: this.session.getGid(),
            source: 'url_params',
            locale: this.lead.get(leadKeys.LOCALE),
            method: 'reactivateLeadFromUrlParams'
          });

          return gid;
        })
        .catch((err: Error) => {
          this.tracker.track(trackerEventNames.REACTIVATION_FAILED, {
            error: err,
            gid: this.lead.get(leadKeys.GID),
            locale: this.lead.get(leadKeys.LOCALE),
            method: 'reactivateLeadFromUrlParams'
          });
        });
    });
  }

  private emitEventLeadAssignmentUpdated(data: any): void {
    const leadAssignmentUpdated = new CustomEvent('leadAssignmentUpdated', { detail: data });
    window.dispatchEvent(leadAssignmentUpdated);
  }

  private getLeadAssignmentEventData(leadResponse: any) {
    const status = leadResponse[leadResponseKey.LEAD_CLOSING_STATUS];
    const journeys = leadResponse[leadResponseKey.RANKED_CUSTOMER_JOURNEY] || [];

    const journeysWithPositiveScore = journeys.filter((item: any) => item[leadResponseKey.ABSOLUTE_SCORE] > 0);
    const matchingJourneysCount = journeysWithPositiveScore.length;
    const winningJourney = journeys.length > 0 ? journeys[0] : null;

    return {
      status,
      matchingJourneysCount,
      winningJourney,
      journeys
    };
  }

  /**
   * @param lead
   */
  public submitToLeadHandler(lead: ILeadData) {
    this.tracker.track(trackerEventNames.LEAD_SUBMITTING_TO_LEADHANDLER, {
      gid: this.lead.get(leadKeys.GID),
      locale: this.lead.get(leadKeys.LOCALE),
      realLead: this.lead.hasPhone(),
      method: 'submitToLeadHandler'
    });

    const gid = this.session.getGid();
    return this.api
      .post(
        `${LeadhandlerService.ENDPOINT_LEADHANDLER}?gid=${gid}`,
        lead,
        {
          Token: gid
        },
        {
          retryAttempts: LeadhandlerService.MAX_RETRY_REQUEST_ATTEMPTS,
          retryDelay: LeadhandlerService.REQUEST_ATTEMPT_DELAY
        }
      )
      .then((response: any): void => {
        Cookie.set(CookieKey.GID, this.session.getGid(), CookieLifetime.ONE_HOUR);
        Cookie.set(CookieKey.LEAD_ID, this.session.getLeadId(), CookieLifetime.ONE_HOUR);

        const leadResponse = response.length > 0 ? response[0] : null;
        const leadClosingStatus = leadResponse?.[leadResponseKey.LEAD_CLOSING_STATUS];

        const leadLocale = this.lead.get(leadKeys.LOCALE);

        if (leadClosingStatus) {
          this.lead.add(leadKeys.CLOSING_SERVICE_STATUS, leadClosingStatus);

          const leadAssignmentData = this.getLeadAssignmentEventData(leadResponse);
          this.emitEventLeadAssignmentUpdated(leadAssignmentData);

          this.gtmService.push({
            ...leadAssignmentData,
            event: 'closingServiceEvent'
          });

          this.tracker.track(trackerEventNames.LEAD_CLOSING_STATUS_ATTACHED, {
            gid: this.lead.get(leadKeys.GID),
            locale: leadLocale,
            method: 'getLeadClosingStatus',
            landingURL: this.lead.get(leadKeys.LANDING_URL),
            leadClosingStatus: this.lead.get(leadKeys.CLOSING_SERVICE_STATUS)
          });
        }

        this.emit(emitterEvents.LEAD_CREATED);

        return response;
      });
  }

  private querySelectFormClientDomElement(): Element {
    return (
      document.querySelector(domSelector.CLIENT_INJECTOR) || document.querySelector(domSelector.FORM_CLIENT_INJECTOR)
    );
  }

  private buildPushKey(formClientDomElement: Element): string {
    const questionnaireId = formClientDomElement.getAttribute('data-id');
    const offerType = formClientDomElement.getAttribute('data-offer-type') || '';

    return `${questionnaireId}-${offerType}`;
  }

  private flattenLeadBuffer(bufferCacheKey: string) {
    const questions = this.buffer.flatten(bufferCacheKey);
    this.lead.extend(questions);
  }

  public async getCampaignAndMarketingData(): Promise<IMarketingData> {
    const initialOffer = await this.marketingService.getInitialOffer();
    const finalMarketingOffer = this.marketingService.getFinalOffer() || initialOffer;

    try {
      const [marketingData = {}, campaign] = await Promise.all([
        this.marketingService.getData(),
        this.campaignService.getData()
      ]);

      this.tracker.track(trackerEventNames.MARKETING_SERVICE_REQUEST_SUCCESS, marketingData);
      this.tracker.track(trackerEventNames.CAMPAIGN_SERVICE_REQUEST_SUCCESS, campaign);

      const {
        act: act__c,
        partner: Marketing_Partner__c,
        channel: Controlling_Channel__c,
        usage: Usage__c,
        offer
      } = campaign;

      const hasMeta = marketingData.browser && marketingData.os;

      return {
        act__c,
        Marketing_Partner__c,
        Controlling_Channel__c,
        Usage__c,
        Initial_Offer__c: offer,
        Marketing_Offer_V2__c: finalMarketingOffer || offer,
        IP_Address__c: marketingData.browser?.ip,
        Latitude: marketingData.location?.latitude || null,
        Longitude: marketingData.location?.longitude || null,
        Browser__c: hasMeta
          ? [
              marketingData.browser?.version || '',
              marketingData.browser.name || '',
              marketingData.os?.name || '',
              marketingData.os?.version || ''
            ].join(' | ')
          : '',
        User_Device__c: utils.ucfirst(marketingData.browser?.device || '')
      };
    } catch (e) {
      return {
        Initial_Offer__c: initialOffer,
        Marketing_Offer_V2__c: finalMarketingOffer
      };
    }
  }

  /**
   * TODO	 move this to dedicated class or better let a UUID object handle this
   * @param bufferCacheKey
   */
  private validateBufferCacheKey(bufferCacheKey: string) {
    const questionnaireId = this.extractQuestionnaireId(bufferCacheKey);

    if (!questionnaireId) {
      return null;
    }

    return bufferCacheKey;
  }

  /**
   * updating the existing lead
   * @param {Object} data
   */
  private async submitLeadUpdates(data: Record<string, any> = {}) {
    this.lead.extend(data);

    this.tracker.track(trackerEventNames.LEAD_SUBMIT_STARTED, {
      gid: this.lead.get(leadKeys.GID),
      locale: this.lead.get(leadKeys.LOCALE),
      realLead: this.lead.hasPhone(),
      method: 'update',
      landingURL: this.lead.get(leadKeys.LANDING_URL)
    });

    return this.submitToLeadHandler(this.lead.getData())
      .then(() => {
        this.tracker.track(trackerEventNames.LEAD_UPDATE_SUCCEEDED, {
          gid: this.lead.get(leadKeys.GID),
          locale: this.lead.get(leadKeys.LOCALE),
          realLead: this.lead.hasPhone(),
          method: 'update'
        });
      })
      .catch((err: Error) => {
        this.tracker.track(trackerEventNames.LEAD_SUBMIT_FAILED, {
          error: (err && err.message) || '',
          gid: this.lead.get(leadKeys.GID),
          locale: this.lead.get(leadKeys.LOCALE),
          method: 'update',
          realLead: this.lead.hasPhone()
        });

        return Promise.reject(err);
      });
  }

  private pushToBuffer(bufferCacheKey: string, value: any) {
    this.buffer.add(bufferCacheKey, value);
    this.buffers = this.buffer.getData(); // backward compatibility

    return this;
  }

  private removeLastFromBuffer(id: string) {
    this.buffer.removeLast(id);
    this.buffers = this.buffer.getData(); // backward compatibility
    return this;
  }

  public pop(id: string) {
    this.removeLastFromBuffer(id);
  }

  private extractQuestionnaireId(bufferCacheKey: string) {
    const questionnaireMatches = bufferCacheKey.match(/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/i);

    if (!questionnaireMatches) {
      return '';
    }

    return questionnaireMatches.pop();
  }

  /**
   * @param leadData
   * @param bufferCacheKey
   */
  private saveToBufferAndSubmit(leadData: Record<string, any>, bufferCacheKey?: string) {
    if (!bufferCacheKey || !leadData) {
      throw new Error(trackerEventNames.LEAD_CHUNK_BUFFER_FAILED);
    }

    const gid = this.session.getGidFromCookie();
    const questionnaireId = this.extractQuestionnaireId(bufferCacheKey);
    const locale = this.lead.get(leadKeys.LOCALE);

    this.pushToBuffer(bufferCacheKey, leadData);
    this.flattenLeadBuffer(bufferCacheKey);

    if (!this.session.isValid()) {
      return Promise.resolve();
    }

    if (gid) {
      this.tracker.track(trackerEventNames.LEAD_CHUNK_BUFFERED, {
        gid,
        locale,
        realLead: this.lead.hasPhone(),
        method: 'submitLeadUpdates'
      });
      return this.submitLeadUpdates();
    }

    this.tracker.track(trackerEventNames.LEAD_CHUNK_BUFFERED, {
      gid: this.lead.get(leadKeys.GID),
      locale,
      realLead: this.lead.hasPhone(),
      method: 'submitNewLead'
    });

    const nowTimestamp = new Date().getTime();

    this.localStorage.setItem(localStorageKey.GID, this.session.getGid());
    this.localStorage.setItem(localStorageKey.LEAD_ID, this.session.getLeadId());
    this.localStorage.setItem(localStorageKey.LEAD_CREATION_TIME, nowTimestamp.toString());

    return this.submitNewLead(questionnaireId);
  }

  public async attachMarketingInfo() {
    const data = await this.getCampaignAndMarketingData();
    this.lead.extend(data);
  }

  /**
   *
   * @param {string} questionnaireId?
   */
  private async submitNewLead(questionnaireId?: string): Promise<any> {
    this.lead.extend({
      Questionnaire__c: questionnaireId || '',
      Pre_Lead_URL__c: decodeURIComponent(document.referrer),
      Lead_URL__c: this.session.getUrl(),
      created: new Date().toUTCString()
    });

    this.lead.updateDmcField();
    this.lead.addMarketingSalesTrackingValue(this.options.gtmId);

    const lead = this.lead.getData();

    this.tracker.track(trackerEventNames.LEAD_SUBMIT_STARTED, {
      gid: this.lead.get(leadKeys.GID),
      locale: this.lead.get(leadKeys.LOCALE),
      realLead: this.lead.hasPhone(),
      method: 'create',
      landingURL: this.lead.get(leadKeys.LANDING_URL)
    });

    return this.submitToLeadHandler(lead)
      .then(() => {
        this.tracker.track(trackerEventNames.LEAD_CREATE_SUCCEEDED, {
          gid: this.lead.get(leadKeys.GID),
          locale: this.lead.get(leadKeys.LOCALE),
          realLead: this.lead.hasPhone(),
          method: 'create'
        });
      })
      .catch((error: Error) => {
        this.tracker.track(trackerEventNames.LEAD_SUBMIT_FAILED, {
          gid: this.lead.get(leadKeys.GID),
          locale: this.lead.get(leadKeys.LOCALE),
          realLead: this.lead.hasPhone(),
          error: (error && error.message) || '',
          method: 'create'
        });

        return Promise.reject(error);
      });
  }

  public attachOptimizelyInfo() {
    const data = this.optimizelyService.getData();
    this.lead.extend(data);
  }

  private async getShortLead(gid: string, leadId: string): Promise<any> {
    if (!this.session.isValidGid(gid) || !this.session.isValidLeadId(leadId)) {
      return null;
    }

    const url = new URL(`${gid}/short`, LeadhandlerService.ENDPOINT_LEADHANDLER);
    url.search = new URLSearchParams({ [urlParameter.LEAD_ID]: leadId }).toString();

    try {
      return await this.api.get(url.href);
    } catch {
      return null;
    }
  }

  private async getLeadStatus(gid: string, leadId: string): Promise<string> {
    const shortLead = await this.getShortLead(gid, leadId);

    return shortLead?.fields[leadKeys.STATUS] || '';
  }

  public async getPreviousLeadStatus(): Promise<string> {
    return await this.getLeadStatus(this.session.getGidFromLocalStorage(), this.session.getLeadIdFromLocalStorage());
  }

  private updateGidAroundAllSessionLayer(gid: string): void {
    this.session.setGid(gid);
    this.lead.add(leadKeys.GID, gid);
    this.sessionStorage.setItem(sessionStorageKey.SESSION_GID, gid);
    this.localStorage.setItem(localStorageKey.REACTIVATION_GID, gid);

    this.gtmService.push({
      event: 'leadReactivationEvent',
      gid
    });
  }
}

export default LeadhandlerService;
